import React from "react"
import { Accordion, Button, Card, Badge, Container } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ReadingMaterial = () => (
  <React.Fragment>
  <Layout pageInfo={{ pageName: " Reading Material" }}>
    <SEO title="Reading Material" />
    <Container>
      <Accordion>
        <Accordion.Item eventKey="146746357346">
          <Accordion.Header>M.Ed.</Accordion.Header>
          <Accordion.Body>
            
              <p>
                <a href="../documents/M.Ed.Syllabus.pdf">
                  <Button variant="outline-dark">
                    Syllabus for M.Ed. <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="23264743523647">
          <Accordion.Header>B.Ed.</Accordion.Header>
          <Accordion.Body>
           
              <p>
                <a href="../documents/B.Ed.Syllabus.pdf">
                  <Button variant="outline-dark">
                    Syllabus for B.Ed. <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
             
          </Accordion.Body>
        </Accordion.Item>
        
      </Accordion>
    </Container>
  </Layout>
  </React.Fragment>
)

export default ReadingMaterial
